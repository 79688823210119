import { render, staticRenderFns } from "./CompanyTagsTable.vue?vue&type=template&id=407a04ff&scoped=true"
import script from "./CompanyTagsTable.vue?vue&type=script&setup=true&lang=ts"
export * from "./CompanyTagsTable.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CompanyTagsTable.vue?vue&type=style&index=0&id=407a04ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407a04ff",
  null
  
)

export default component.exports