import { render, staticRenderFns } from "./CompanyTagForm.vue?vue&type=template&id=6cfd3552&scoped=true"
import script from "./CompanyTagForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./CompanyTagForm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CompanyTagForm.vue?vue&type=style&index=0&id=6cfd3552&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cfd3552",
  null
  
)

export default component.exports